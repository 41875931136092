import React, { useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/styles";

import gradients from "utils/gradients";

import useRouter from "utils/useRouter";
import axios from "utils/axios";
import Cookies from "js-cookie";
import crypto from "crypto-js";
import { AppContext } from "../../App";
import { jwtDecode } from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "unset",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const LoginOidc = async (props) => {
  const classes = useStyles();
  const router = useRouter();

  let oidcUser = localStorage.getItem("oidcUser");

  let formatedOutput = JSON.parse(oidcUser);

  const decodedToken = jwtDecode(formatedOutput.access_token);
  const accessLevel = decodedToken && decodedToken.access;

  const getUserLevel = (keywords) => {
    const index = keywords.findIndex((str) => str.includes("bestoflvl"));

    if (index > -1) {
      let keyword = keywords[index];
      const charIndex = keyword.indexOf("-");
      const nextChar = keyword[charIndex + 1];
      return nextChar;
    } else {
      return 2;
    }
  };

  const assignUserLevel = (accessLevel) => {
    const keywords = accessLevel?.split(",") || [];
    let userLevel = getUserLevel(keywords);

    Cookies.set("bestofuserlevel", userLevel);
  };
  setTimeout(assignUserLevel(accessLevel), 500);

  let email = formatedOutput.profile["email"];

  const updateIntercom = (data) => {
    let hash = crypto.HmacSHA256(
      data.user_id,
      process.env.REACT_APP_INTERCOM_WEB_IDENTITY_SECRET_KEY
    );

    let hashInHex = crypto.enc.Hex.stringify(hash);

    window.Intercom("boot", {
      app_id: "p43ayu3j",
      name: data.name, // Full name
      email: data.email, // Email address
      user_id: data.user_id,
      organization_name: data.organization_name,
      user_hash: hashInHex,
    });
  };

  const processInercom = (data) => {
    if (data.user.super_admin) {
      let tempSuperUserData = {
        name: data.user.name,
        email: data.user.email,
        user_id: data.user._id,
        organization_name: "super user",
      };
      updateIntercom(tempSuperUserData);

      router.history.push("/all-customers");
      window.location.reload();
    } else {
      let axios_path_to =
        process.env.REACT_APP_BACK_END_SERVER_URL +
        "/server/v1/customers/" +
        data.user.owner_id;
      axios.get(axios_path_to).then(
        (response) => {
          if (response.status === 200) {
            let tempData = {
              name: data.user.name, // Full name
              email: data.user.email, // Email address
              user_id: data.user._id,
              organization_name: response.data.name,
            };
            updateIntercom(tempData);
            window.clarity("set", "customerName", data.user.name);
            window.clarity("set", "customerEmail", data.user.email);
            Cookies.set("customer_id", data.user.owner_id);
            Cookies.set("customer_name", data.user.name);
            Cookies.set("customer_slug", response.data.slug);
            Cookies.set(
              "self_serve_ads",
              response.data.subscription &&
                response.data.subscription.self_serve_ads &&
                response.data.subscription.self_serve_ads.enable
                ? "true"
                : "false"
            );
            Cookies.set(
              "self_serve_emails",
              response.data.subscription &&
                response.data.subscription.self_serve_emails &&
                response.data.subscription.self_serve_emails.enable
                ? "true"
                : "false"
            );
            Cookies.set(
              "connected_to_stripe",
              response.data &&
                response.data.subscription &&
                response.data.subscription.stripe &&
                response.data.subscription.stripe.connected_account_id != ""
                ? true
                : false
            );
            Cookies.set(
              "enable_winners_portal",
              typeof response.data.enable_winners_portal == "undefined"
                ? true
                : response.data.enable_winners_portal
            );

            router.history.push("/ballots");
            window.location.reload();
          } else {
            router.history.push("/auth/login");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const payload = {
    email: email.toLowerCase(),
  };
  let axios_path_to =
    process.env.REACT_APP_BACK_END_SERVER_URL +
    "/server/v1/customers_auth/authenticateCustomerAuthForOidc";
  let contactSalesPath =
    window.location.origin + "/auth/contact-sales?email=" + email;

  axios.post(axios_path_to, payload).then(
    (response) => {
      if (response.status === 200) {
        Cookies.set("token", response.data.token);
        Cookies.set("super_admin", response.data.user.super_admin);
        Cookies.set("member_role", response.data.user.role);
        Cookies.set("show_org_nav", true);
        Cookies.set("customer_id", response.data.user.owner_id);
        Cookies.set("member_id", response.data.user._id);
        Cookies.set("logged_in_user_name", response.data.user.name);
        Cookies.set("logged_using_fire_base", true);

        processInercom(response.data);
      } else {
        window.location.replace(contactSalesPath);
      }
    },
    (error) => {
      window.location.replace(contactSalesPath);
    }
  );

  return <div />;
};

export default LoginOidc;
